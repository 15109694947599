import { Turbo } from '@hotwired/turbo-rails'
window.Turbo = Turbo

// Prevent Turbo Drive from using cache as it leads to
// unexpected behavior with form submissions and browser history
document.addEventListener('turbo:before-visit', function () {
  Turbo.cache.clear()
})

// Only used in the dashboard
Turbo.setConfirmMethod((message, element) => {
  let dialog = document.getElementById('turbo_confirm_dialog')
  dialog.showModal()
  const header = document.getElementById('confirm-dialog-header')
  const content = document.getElementById('confirm-dialog-text-content')
  header.textContent = element.dataset.header
  content.textContent = message

  return new Promise(resolve => {
    dialog.addEventListener(
      'close',
      () => {
        resolve(dialog.returnValue == 'confirm')
      },
      { once: true }
    )
  })
})
